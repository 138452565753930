import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-modal-reserva',
  templateUrl: './modal-reserva.component.html'
})
export class ModalReservaComponent implements OnInit {
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    $.modalreservashow = () => {
      this.data = $.modalreservadata;
      if (!this.data) return;
      $('#modal-reserva').modal('show');
      $('#modal-reserva .modal-title').html('Reserva <b>#' + this.data.referencia + '</b>');
    }
    $.modalreservahide = () => {
      $('#modal-reserva').modal('hide');
    }
  }
  Editar() {
    $('#modal-reserva').modal('hide');
    this.globals.passData = JSON.parse(JSON.stringify(this.data));
    this.ngZone.run(() => this.router.navigateByUrl('/reservas/edit')).then();
  }
  Borrar() {
    if ($.modalreservadelete) {
      $.modalreservadelete(this.data);
    }
  }
  CancelarReserva() {
    swal({
      title: "¿Cancelar esta reserva?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.2)',
        imageResizeFactor: 0.5,
        text: 'Espere por favor ...'
      });
      $('#modal-reserva').modal('hide');
      this.globals.Api('/alo-reservas-cancelar', {id: this.data.id}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.id_estado = 4;
        this.data.estado = 'Pendiente cancelación';
        this.appref.tick();
        toastr.success(`Su reserva a pasado a "pendiente de cancelación"`, 'Solicitud enviada');
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    });
  }
}
