<div id="sidebar">
    <!-- Sidebar Brand -->
    <div id="sidebar-brand" class="themed-background">
        <a [routerLink]="['/dashboard']" class="sidebar-title">
            <img src="assets/logo-isotipo.svg" alt=""> <span class="sidebar-nav-mini-hide m-l-10">SPGE<strong>Host</strong></span>
        </a>
    </div>
    <!-- END Sidebar Brand -->

    <!-- Wrapper for scrolling functionality -->
    <div id="sidebar-scroll">
        <!-- Sidebar Content -->
        <div class="sidebar-content">
            <!-- Sidebar Navigation -->
            <ng-container *ngIf="globals && globals.me">
                <!-- ADMIN -->
                <ng-container *ngIf="globals.me.id_rol == 0 || globals.me.id_rol == 1 || globals.me.id_rol == 2">
                    <ul class="sidebar-nav">
                        <li>
                            <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a [routerLink]="['/configuracion']" routerLinkActive="active"><i class="icofont-settings sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Configuración</span></a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlaalojamientos.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-hotel sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Alojamientos</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/alojamientos']" routerLinkActive="active" #rlaalojamientos="routerLinkActive">Todos los alojamientos</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/temporadas']" routerLinkActive="active" #rlaalojamientos="routerLinkActive"><i class="icofont-sun-rise"></i> Temporadas</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/alojamientos/add']" routerLinkActive="active" #rlaalojamientos="routerLinkActive"><i class="fa fa-plus"></i> Nuevo alojamiento</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlareservas.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Reservas</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/reservas']" routerLinkActive="active" #rlareservas="routerLinkActive">Todas las reservas</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/reservas/add']" routerLinkActive="active" #rlareservas="routerLinkActive"><i class="fa fa-plus"></i> Nueva reserva</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="sidebar-nav-menu cursor" 
                                [class.open]="rlaclientes.isActive"
                                [class.open]="rlaclientes.isActive"
                                >
                                <i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="icofont-ui-user-group sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Clientes</span>
                            </a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/clientes']" routerLinkActive="active" #rlaclientes="routerLinkActive">Todos los clientes</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/clientes/add']" routerLinkActive="active" #rlaclientes="routerLinkActive"><i class="fa fa-plus"></i> Nuevo cliente</a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a href="javascript:void(0);" class="sidebar-nav-menu" [class.open]="rlausuarios.isActive"><i class="fa fa-chevron-left sidebar-nav-indicator sidebar-nav-mini-hide"></i>
                                <i class="fa fa-users sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Usuarios</span></a>
                            <ul>
                                <li>
                                    <a [routerLink]="['/usuarios']" routerLinkActive="active" #rlausuarios="routerLinkActive">Todos los usuarios</a>
                                </li>
                                <li>
                                    <a [routerLink]="['/usuarios/add']" routerLinkActive="active" #rlausuarios="routerLinkActive"><i class="fa fa-plus"></i> Nuevo usuario</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </ng-container>
                <!-- CLIENTE -->
                <ng-container *ngIf="globals.me.id_rol == 3">
                    <ul class="sidebar-nav">
                        <li>
                            <a [routerLink]="['/dashboard']" routerLinkActive="active"><i class="gi gi-home sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Inicio</span></a>
                        </li>
                        <li class="sidebar-separator">
                            <i class="fa fa-ellipsis-h"></i>
                        </li>
                        <li>
                            <a [routerLink]="['/mi-perfil']" routerLinkActive="active"><i class="icofont-user sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mi perfil</span></a>
                        </li>
                        <li>
                            <a [routerLink]="['/mis-reservas']" routerLinkActive="active"><i class="icofont-travelling sidebar-nav-icon"></i><span class="sidebar-nav-mini-hide">Mis reservas</span></a>
                        </li>
                    </ul>
                </ng-container>
            </ng-container>
            <!-- END Sidebar Navigation -->
        </div>
        <!-- END Sidebar Content -->
    </div>
    <!-- END Wrapper for scrolling functionality -->

    <!-- Sidebar Extra Info -->
    <div id="sidebar-extra-info" class="sidebar-content sidebar-nav-mini-hide">
        <div class="text-center">
            <small>Creado por <a href="https://pecesgordos.es/" target="_blank" class="text-primary">Peces Gordos Estudio</a></small><br>
        </div>
    </div>
    <!-- END Sidebar Extra Info -->
</div>