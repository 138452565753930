import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ICAL: any;

@Component({
  selector: 'app-edit-reserva',
  templateUrl: './edit-reserva.component.html'
})
export class EditReservaComponent implements OnInit {
  public title:string = 'Nueva reserva';
  public data:any = {
    id: ''
  };
  public fechas_bloqueadas = [];
  public fechas_bloqueadas_booking = [];
  public fechas_bloqueadas_airbnb= [];
  
  public estados = [];
  public alojamientos = [];
  public params_alo:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public tarifas = [];
  public pagos = [];
  public dataitem:any = {};
  public noches:number = 1;
  public estados_pago = [];
  public formas_pago = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nueva reserva';
    } else {
      this.title = 'Reserva #'+this.data.referencia;
    }
    if (this.data.noches) this.noches = this.data.noches;
    this.LoadFechasBloqueadas();
    if (this.data.id && this.data.id != '') {
      if (this.data.fecha_llegada && this.data.fecha_salida) {
        $('#txtfechallegadasalida').val(moment(this.data.fecha_llegada).format('DD/MM/YYYY') + ' - ' + moment(this.data.fecha_salida).format('DD/MM/YYYY'));
        $('#txtfechallegadasalida').data('daterangepicker').setStartDate(moment(this.data.fecha_llegada));
        $('#txtfechallegadasalida').data('daterangepicker').setEndDate(moment(this.data.fecha_salida));
      }
    }
    
    if (this.globals.GetData()) this.estados = this.globals.GetData('estados_reserva');
    this.globals.Api('/alo-estado-reserva').subscribe(data => {
      if (!data || !data.length) {
        this.estados = [];
        this.globals.SaveData([], 'estados_reserva');
        return;
      }
      this.estados = data;
      if (!this.data.id_estado && this.estados.length) this.data.id_estado = this.estados[0].id;
      this.globals.SaveData(this.estados, 'estados_reserva');
    });
    if (this.globals.GetData()) this.estados_pago = this.globals.GetData('estados_pago');
    this.globals.Api('/alo-estado-pago').subscribe(data => {
      if (!data || !data.length) {
        this.estados_pago = [];
        this.globals.SaveData([], 'estados_pago');
        return;
      }
      this.estados_pago = data;
      this.globals.SaveData(this.estados_pago, 'estados_pago');
    });
    if (this.globals.GetData()) this.formas_pago = this.globals.GetData('formas_pago');
    this.globals.Api('/alo-forma-pago').subscribe(data => {
      if (!data || !data.length) {
        this.formas_pago = [];
        this.globals.SaveData([], 'formas_pago');
        return;
      }
      this.formas_pago = data;
      if (!this.data.cli_tipo_pago || this.data.cli_tipo_pago == '') {
        this.data.cli_tipo_pago = this.formas_pago.find(el => el.pordefecto == 1).slug;
      }
      this.globals.SaveData(this.formas_pago, 'formas_pago');
    });
  }
  LoadFechasBloqueadas() {
    function getDates(startDate, stopDate) {
      let dateArray = [];
      let currentDate = new Date(startDate);
      stopDate = new Date(stopDate);
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dateArray;
    }
    if (this.data.id_alojamiento && this.data.id_alojamiento != '') {
      this.globals.Api('/alo-alojamientos', {id: this.data.id_alojamiento, id_reserva: this.data.id}).subscribe(data => {
        if (!data || !data.length) return;
        data = data[0];
        this.fechas_bloqueadas = [];
        if (data.fechas_bloqueadas) {
          for (let item of data.fechas_bloqueadas) {
            if (!item || !item.fecha_format2) continue;
            this.fechas_bloqueadas.push(item.fecha_format2);
          }
        }
        if (data.fechas_reservadas) {
          for (let item of data.fechas_reservadas) {
            if (!item || !item.fecha_llegada_format2) continue;
            let dates = getDates(item.fecha_llegada_format2, item.fecha_salida_format2);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas.push(fecha);
            }
          }
        }
      });
      this.globals.Api('/get-ics/'+this.data.id_alojamiento).subscribe(data => {
        if (!data) return;
        this.fechas_bloqueadas_booking = [];
        if (data.ics_booking && data.ics_booking != '') {
          let jcalData = ICAL.parse(data.ics_booking);
          let vcalendar = new ICAL.Component(jcalData);
          let vevents = vcalendar.getAllSubcomponents("vevent");
          for (let event of vevents) {
            let uid = event.getFirstPropertyValue('uid');
            let start = event.getFirstPropertyValue('dtstart');
            let end = event.getFirstPropertyValue('dtend');
            start = moment(new Date(start.year, start.month-1, start.day)).format('YYYY-MM-DD');
            end = moment(new Date(end.year, end.month-1, end.day)).format('YYYY-MM-DD');
            let dates = getDates(start, end);
            // console.log(uid);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas_booking.push(fecha);
            }
          }
        }
        this.fechas_bloqueadas_airbnb = [];
        if (data.ics_airbnb && data.ics_airbnb != '') {
          let jcalData = ICAL.parse(data.ics_airbnb);
          let vcalendar = new ICAL.Component(jcalData);
          let vevents = vcalendar.getAllSubcomponents("vevent");
          for (let event of vevents) {
            let uid = event.getFirstPropertyValue('uid');
            let start = event.getFirstPropertyValue('dtstart');
            let end = event.getFirstPropertyValue('dtend');
            start = moment(new Date(start.year, start.month-1, start.day)).format('YYYY-MM-DD');
            end = moment(new Date(end.year, end.month-1, end.day)).format('YYYY-MM-DD');
            let dates = getDates(start, end);
            // console.log(uid);
            for (let fecha of dates) {
              fecha = moment(fecha).format('YYYY-MM-DD');
              this.fechas_bloqueadas_airbnb.push(fecha);
            }
          }
        }
        this.appref.tick();
        $('#calendario-cierres').datepicker('update');
      });
    }

    $('.rangepicker').daterangepicker({
      alwaysShowCalendars: true,
      opens: 'left',
      autoUpdateInput: false,
      locale: {
        "format": "DD/MM/YYYY",
        "separator": " - ",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancel",
        "fromLabel": "Desde",
        "toLabel": "Hasta",
        "customRangeLabel": "Personalizado",
        "weekLabel": "S",
        "daysOfWeek": [
            "Do",
            "Lu",
            "Ma",
            "Mi",
            "Ju",
            "Vi",
            "Sa"
        ],
        "monthNames": [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        "firstDay": 1
      },
      isInvalidDate: ele => {
        let fecha = moment(ele._d).format('YYYY-MM-DD');
        if (this.fechas_bloqueadas.indexOf(fecha) != -1) {
          return true;
        }
        if (this.fechas_bloqueadas_booking.indexOf(fecha) != -1
          && this.fechas_bloqueadas_airbnb.indexOf(fecha) != -1) {
          return true;
        }
        if (this.fechas_bloqueadas_booking.indexOf(fecha) != -1) {
          return true;
        }
        if (this.fechas_bloqueadas_airbnb.indexOf(fecha) != -1) {
          return true;
        }
        return false;
      }
    }).on('apply.daterangepicker', (ev, picker) => {
      let start = moment(picker.startDate.format('YYYY-MM-DD'));
      let end   = moment(picker.endDate.format('YYYY-MM-DD'));
      let diff = end.diff(start, 'days');
      let clearInput = false;
      for (let fecha of this.fechas_bloqueadas) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      for (let fecha of this.fechas_bloqueadas_booking) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      for (let fecha of this.fechas_bloqueadas_airbnb) {
        if (moment(start)<moment(fecha) && moment(end)>moment(fecha)) {
          clearInput = true;
          break;
        }
      }
      if (clearInput) return false;
      $('#txtfechallegadasalida').val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
      this.data.fecha_llegada = picker.startDate.format('YYYY-MM-DD');
      this.data.fecha_salida = picker.endDate.format('YYYY-MM-DD');
      this.noches = diff;
      this.CalcularTotal();
    }).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  AddAlojamiento() {
    $('#modal-seleccionar-alojamiento').modal('show');
    $('#modal-seleccionar-alojamiento .modal-title').html('Seleccionar alojamiento');
    this.CargarGridAlo();
  }
  EditAlojamiento(item, event, tipo) {
    let html = $(event.target).html().trim();
    if (html.indexOf('<br>') != -1) $(event.target).html(html.replace('<br>', ''));
    let value = $(event.target).html().trim();
    if (isNaN(value)) value = null;
    console.log(value);
    if (tipo == 'adultos') {
      if (!value || value == '') return;
      this.data.adultos = value;
    }
    if (tipo == 'ninos') {
      this.data.ninos = value;
    }
    this.CalcularTotal();
  }
  SeleccionarAlojamiento(item:any) {
    $.tempalo = JSON.parse(JSON.stringify(item));
    if (item.tarifas && item.tarifas.length) this.tarifas = JSON.parse(JSON.stringify(item.tarifas));
    else this.tarifas = [];
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';
    this.data.id_alojamiento = item.id;
    this.data.nombre_alojamiento = item.nombre;
    this.LoadFechasBloqueadas();
    $('#modal-seleccionar-alojamiento').modal('hide');
    $('#modal-seleccionar-tarifa').modal('show');
    $('#modal-seleccionar-tarifa .modal-title').html('Seleccionar tarifa');
  }
  SeleccionarTarifa(item:any) {
    if (
      this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (this.data.ninos == '') this.data.ninos = 0;
    if (!this.data.alojamientos) this.data.alojamientos = [];
    let max = 0;
    this.data.alojamientos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    max = max+1;
    this.data.alojamientos.push({
      id_reserva: '',
      id: max,
      id_alojamiento: $.tempalo.id,
      id_tarifa: item.id,
      alo_nombre: $.tempalo.nombre,
      alo_tarifa: 'Tarifa '+item.temporada,
      adultos: this.data.adultos,
      ninos: this.data.ninos,
      precio: item.precio
    });
    this.CalcularTotal();
    $('#modal-seleccionar-tarifa').modal('hide');
    // this.dataitem.adultos = '';
    // this.dataitem.ninos = '';
  }
  AnadirAlo() {
    if (
      this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    this.CalcularTotal();
    $('#modal-seleccionar-tarifa').modal('hide');
  }
  CalcularTotal() {
    // if (!this.data.alojamientos || !this.data.alojamientos.length) return;
    let total:number = 0;
    // for (let item of this.data.alojamientos) {
    //   item.subtotal = parseFloat(item.precio)*this.noches
    //   if (item.subtotal && !isNaN(item.subtotal)) total += item.subtotal;
    // }
    // let max = 0;
    // this.data.alojamientos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    // max = max+1;
    this.globals.Api('/pub-total-fechas', this.data).subscribe(data => {
      if (!data || !data.total) {
        this.data.alojamientos = [];
        this.data.alojamientos.push({
          id_reserva: '',
          id: 1,
          alo_nombre: 'Seleccione fecha llegada y salida',
          alo_tarifa: 'Seleccione fecha llegada y salida',
        });
        return;
      }
      let total = parseFloat(data.total);
      this.data.iva = 10;
      this.data.base_imponible = total / (1 + (this.data.iva/100));
      this.data.total = total;
      this.data.alojamientos = [];
      this.data.alojamientos.push({
        id_reserva: '',
        id: 1,
        id_alojamiento: this.data.id_alojamiento,
        id_tarifa: null,
        alo_nombre: this.data.nombre_alojamiento,
        alo_tarifa: 'Tarifa '+data.precio_noche+' / noche',
        adultos: this.data.adultos,
        ninos: this.data.ninos,
        precio: data.precio
      });
      this.appref.tick();
    })
  }
  DelAlojamiento(item:any) {
    swal({
      title: "¿Borrar alojamiento?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.data.alojamientos.splice(this.data.alojamientos.findIndex(el => el.id == item.id), 1);
      this.data.base_imponible = 0;
      this.data.total = 0;
      this.appref.tick();
    });
  }

  CargarGridAlo(paginationload:boolean = true) {
    if (this.globals.GetData()) this.alojamientos = this.globals.GetData();
    this.globals.Api('/alo-alojamientos', this.params_alo).subscribe(data => {
      if (!data || !data.length) {
        this.alojamientos = [];return;
      }
      this.globals.SaveData(data);
      this.alojamientos = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/alo-alojamientos/total', this.params_alo).subscribe(data => {
        if (!data || !data.length) return;
        this.params_alo.total = data[0].total;
        this.params_alo.pages = Math.ceil(this.params_alo.total / this.params_alo.page_size);
      });
    });
  }
  PagingAlo(dir, table = '') {
    if (dir == 'prev') {
      this.params_alo.page_index -= 1;
    }
    if (dir == 'next') {
      this.params_alo.page_index += 1;
    }
    this.CargarGridAlo(false);
  }
  BuscarAlo() {
    this.params_alo.page_index = 1;
    this.CargarGridAlo();
  }

  AddPago() {
    $('#modal-crear-pago').modal('show');
    $('#modal-crear-pago .modal-title').html('Nuevo pago');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    if (this.estados_pago.length) this.dataitem.id_estado_pago = this.estados_pago[0].id;
    if (this.formas_pago.length) this.dataitem.id_forma_pago = this.formas_pago[0].id;
    this.dataitem.tipo = 'manual';
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
    setTimeout(() => {
      this.appref.tick();
      $('#txtfechapago').datepicker('setDate', moment().format('DD/MM/YYYY'));
    }, 200);
  }
  EditPago(item:any) {
    $('#modal-crear-pago').modal('show');
    $('#modal-crear-pago .modal-title').html('Editar pago');
    this.dataitem = JSON.parse(JSON.stringify(item));
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
    setTimeout(() => {
      this.appref.tick();
      if (this.dataitem.fecha_pago) $('#txtfechapago').datepicker('setDate', this.data.fecha_pago);
    }, 200);
  }
  DelPago(item:any) {
    swal({
      title: "¿Borrar pago?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.data.pagos.splice(this.data.pagos.findIndex(el => el.id == item.id), 1);
      this.appref.tick();
    });
  }
  GuardarPago() {
    if (
      $('#txtfechapago').val().trim() == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (
      !this.dataitem.id_estado_pago
      || this.dataitem.id_estado_pago == ''
      || !this.dataitem.id_forma_pago
      || this.dataitem.id_forma_pago == ''
      || !this.dataitem.tipo
      || this.dataitem.tipo == ''
      || !this.dataitem.total
      || this.dataitem.total == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.data.pagos) this.data.pagos = [];
    let max = 0;
    this.data.pagos.forEach(item => {if (parseInt(item.id) > max) max = item.id;});
    max = max+1;
    this.data.pagos.push({
      id_reserva: '',
      id: max,
      id_estado_pago: this.dataitem.id_estado_pago,
      id_forma_pago: this.dataitem.id_forma_pago,
      tipo: this.dataitem.tipo,
      fecha_pago: moment($('#txtfechapago').datepicker('getDate')).format('YYYY-MM-DD'),
      fecha_pago_format: moment($('#txtfechapago').datepicker('getDate')).format('DD/MM/YYYY'),
      estado: $('#cmbestadopago option:selected').text(),
      forma_pago: $('#cmbformapago option:selected').text(),
      total: this.dataitem.total
    });
    this.appref.tick();
    $('#modal-crear-pago').modal('hide');
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/reservas')).then();
  }
  Guardar() {
    if (
      !this.data.fecha_llegada
      || this.data.fecha_llegada == ''
      || !this.data.fecha_salida
      || this.data.fecha_salida == ''
      ) {
      swal('Seleccione fechas', 'Por favor, seleccione las fecha para continuar', 'error');return;
    }
    if (
      this.data.cli_nombre == ''
      || this.data.cli_apellidos == ''
      || this.data.cli_email == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.cli_email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }
    if (
      !this.data.alojamientos
      || !this.data.alojamientos.length
      ) {
      swal('Seleccione alojamiento', 'Por favor, seleccione un alojamiento para continuar', 'error');return;
    }

    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Enviando datos ...'
    });
    if (this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/alo-reservas-add', this.data).subscribe(data => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/alo-reservas-edit', this.data).subscribe(data => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        // swal('Guardado', 'Datos guardados correctamente', 'success');
        toastr.success(`Datos guardados correctamente`, 'Guardado');
      }, error => {
        $.LoadingOverlay("hide", true);
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

}
