import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './backend/login/login.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Globals } from './classes/globals.class';
import { MenuComponent } from './backend/controls/menu/menu.component';
import { HeaderComponent } from './backend/controls/header/header.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { AlojamientosComponent } from './backend/alojamientos/alojamientos.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { ReservasComponent } from './backend/reservas/reservas.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { EditAlojamientoComponent } from './backend/alojamientos/edit-alojamiento/edit-alojamiento.component';
import { EditReservaComponent } from './backend/reservas/edit-reserva/edit-reserva.component';
import { TemporadasComponent } from './backend/temporadas/temporadas.component';
import { ModalReservaComponent } from './backend/controls/modal-reserva/modal-reserva.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    MenuComponent,
    HeaderComponent,
    ConfigAppComponent,
    UsuariosComponent,
    EditUsuarioComponent,
    AlojamientosComponent,
    ConfiguracionComponent,
    ReservasComponent,
    MiPerfilComponent,
    EditAlojamientoComponent,
    EditReservaComponent,
    TemporadasComponent,
    ModalReservaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
