import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlojamientosComponent } from './backend/alojamientos/alojamientos.component';
import { EditAlojamientoComponent } from './backend/alojamientos/edit-alojamiento/edit-alojamiento.component';
import { ConfigAppComponent } from './backend/config-app/config-app.component';
import { ConfiguracionComponent } from './backend/configuracion/configuracion.component';
import { DashboardComponent } from './backend/dashboard/dashboard.component';
import { LoginComponent } from './backend/login/login.component';
import { MiPerfilComponent } from './backend/mi-perfil/mi-perfil.component';
import { EditReservaComponent } from './backend/reservas/edit-reserva/edit-reserva.component';
import { ReservasComponent } from './backend/reservas/reservas.component';
import { TemporadasComponent } from './backend/temporadas/temporadas.component';
import { EditUsuarioComponent } from './backend/usuarios/edit-usuario/edit-usuario.component';
import { UsuariosComponent } from './backend/usuarios/usuarios.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'admincontrol', component: LoginComponent},
  {path: 'dashboard', component: DashboardComponent},
  {path: 'config-app', component: ConfigAppComponent},
  {path: 'configuracion', component: ConfiguracionComponent},
  {path: 'mi-perfil', component: MiPerfilComponent},
  {path: 'usuarios', component: UsuariosComponent},
  {path: 'usuarios/add', component: EditUsuarioComponent},
  {path: 'usuarios/edit', component: EditUsuarioComponent},
  {path: 'clientes', component: UsuariosComponent},
  {path: 'clientes/add', component: EditUsuarioComponent},
  {path: 'clientes/edit', component: EditUsuarioComponent},
  {path: 'temporadas', component: TemporadasComponent},
  {path: 'alojamientos', component: AlojamientosComponent},
  {path: 'alojamientos/add', component: EditAlojamientoComponent},
  {path: 'alojamientos/edit', component: EditAlojamientoComponent},
  {path: 'reservas', component: ReservasComponent},
  {path: 'mis-reservas', component: ReservasComponent},
  {path: 'reservas/add', component: EditReservaComponent},
  {path: 'reservas/edit', component: EditReservaComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
