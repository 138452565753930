<!-- Full Background -->
<!-- For best results use an image with a resolution of 1280x1280 pixels (prefer a blurred image for smaller file size) -->
<img src="assets/bg-login-2.jpg" alt="Full Background" class="full-bg animation-pulseSlow" style="object-fit:cover;">
<!-- END Full Background -->

<!-- Login Container -->
<div id="login-container">
    <!-- Login Header -->
    <h1 class="h2 text-light text-center push-top-bottom animation-pullDown sombra-texto">
        <img src="assets/logo-isotipo.svg" class="logo"> <strong>SPGE Host</strong>
    </h1>
    <!-- END Login Header -->

    <!-- Login Block -->
    <div class="block animation-fadeInQuick {{!recuperarclave ? '' : 'hidden'}}">
        <!-- Login Title -->
        <div class="block-title">
            <h2>Panel de administraci&oacute;n</h2>
        </div>
        <!-- END Login Title -->

        <!-- Login Form -->
        <div id="form-login" class="form-horizontal">
            <div class="form-group">
                <label for="login-email" class="col-xs-12">Email</label>
                <div class="col-xs-12">
                    <input type="text" id="txtEmail" name="login-email" class="form-control" [(ngModel)]="email" placeholder="Tu email ..." autocomplete="off">
                </div>
            </div>
            <div class="form-group">
                <label for="login-password" class="col-xs-12">Contrase&ntilde;a</label>
                <div class="col-xs-12">
                    <input type="password" id="txtClave" name="login-password" class="form-control" [(ngModel)]="clave" placeholder="Tu contrase&ntilde;a ..." autocomplete="off">
                </div>
            </div>
            <div class="form-group form-actions">
                <div class="col-xs-6">
                    <label class="csscheckbox csscheckbox-primary">
                        <!-- <input type="checkbox" id="login-remember-me" name="login-remember-me"><span></span> Remember Me? -->
                        <a class="cursor" (click)="recuperarclave = true"><small>¿Has olvidado tu contrase&ntilde;a?</small></a>
                    </label>
                </div>
                <div class="col-xs-6 text-right">
                    <a id="btnAcceder" class="btn btn-effect-ripple btn-primary text-uppercase" (click)="Login()">Acceder <i class="fa fa-chevron-right m-l-10"></i></a>
                </div>
            </div>
        </div>
        <!-- END Login Form -->
    </div>
    <div class="block animation-fadeInQuick {{recuperarclave ? '' : 'hidden'}}">
        <!-- Login Title -->
        <div class="block-title">
            <h2>Recuperaci&oacute;n contrase&ntilde;a</h2>
        </div>
        <div id="form-login" class="form-horizontal">
            <p>Introduce tu correo y te enviaremos tu nueva contrase&ntilde;a</p>
            <div class="form-group">
                <div class="col-xs-12">
                    <input id="txtEmailRecuperar" type="email" class="form-control" placeholder="Email" [(ngModel)]="email" required>
                </div>
            </div>
            <div class="text-center">
                <a id="btnRecuperarClave" class="btn btn-effect-ripple btn-primary btn-block cursor" (click)="RecuperarClave()">Enviar correo recuperaci&oacute;n</a><br>
                <button type="button" class="btn btn-secondary m-t-10 m-b-20" (click)="recuperarclave = false">Atr&aacute;s</button>
            </div>
        </div>
    </div>
    <!-- END Login Block -->

    <!-- Footer -->
    <footer class="text-muted text-center animation-pullUp">
        <small><span id="year-copy"></span> &copy; <a href="https://pecesgordos.es" target="_blank">Peces Gordos Estudio</a></small>
    </footer>
    <!-- END Footer -->
</div>
<!-- END Login Container -->