<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle" *ngIf="!misreservas">Reservas</h1>
                                <h1 class="middle" *ngIf="misreservas">Mis reservas</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li *ngIf="!misreservas">Reservas</li>
                                <li *ngIf="misreservas">Mis reservas</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center" *ngIf="!misreservas">
                          <a class="btn btn-primary btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva reserva</a>
                        </div>
                    </div>
                </div>
                <!-- Table Styles Content -->
                <div class="table-responsive table-grid block full break-padding">
                    <table class="table table-striped table-bordered table-vcenter table-hover">
                        <thead>
                            <tr>
                              <th order="referencia">Referencia</th>
                              <th style="width:100px" order="alta">Alta</th>
                              <th style="width:150px" order="estado">Estado</th>
                              <th>Fechas</th>
                              <th>Personas</th>
                              <th>Cliente</th>
                              <th>Total</th>
                              <ng-container *ngIf="!misreservas">
                                <th style="width:50px">Editar</th>
                                <th style="width:50px">Borrar</th>
                              </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngIf="!misreservas">
                            <ng-container *ngFor="let item of reservas">
                              <tr class="cursor" *ngIf="(!item.id || item.id == '') && item.tipo == 'booking'">
                                <td class="">
                                  <div class="font-bold text-primary">
                                    <img src="assets/logo-booking.png" alt="" style="height:22px;margin-right:5px;margin-bottom:5px;">
                                    Reserva Booking
                                  </div>
                                  <div class="font-bold">{{item.titulo}}</div>
                                </td>
                                <td></td>
                                <td>Confirmada</td>
                                <td>
                                    <div>Llegada: {{item.fecha_llegada_format}}</div>
                                    <div>Salida: {{item.fecha_salida_format}}</div>
                                    <div>Noches: {{item.noches}}</div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr class="cursor" *ngIf="(!item.id || item.id == '') && item.tipo == 'airbnb'">
                                <td class="">
                                  <div class="font-bold text-primary">
                                    <img src="assets/logo-airbnb.png" alt="" style="height:22px;margin-right:5px;margin-bottom:5px;">
                                    Reserva Airbnb
                                  </div>
                                  <div class="font-bold">{{item.titulo}}</div>
                                </td>
                                <td></td>
                                <td>Confirmada</td>
                                <td>
                                    <div>Llegada: {{item.fecha_llegada_format}}</div>
                                    <div>Salida: {{item.fecha_salida_format}}</div>
                                    <div>Noches: {{item.noches}}</div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr class="cursor {{item.id_estado == 4 || item.id_estado == 5 ? 'bg-tr-cancelada' : null}}"
                                *ngIf="item.id && item.id != ''">
                                <td class="" (click)="Editar(item)">
                                  <div class="font-bold text-primary">Reserva #{{item.referencia}}</div>
                                  <div class="font-bold">{{item.alojamiento}}</div>
                                </td>
                                <td>{{item.alta_format}}</td>
                                <td>
                                  <div>{{item.estado}}</div>
                                  <div *ngIf="item.forma_pago && item.forma_pago != '0'" class="font-11 text-primary">{{item.forma_pago}}</div>
                                </td>
                                <td>
                                    <div>Llegada: {{item.fecha_llegada_format}}</div>
                                    <div>Salida: {{item.fecha_salida_format}}</div>
                                    <div>Noches: {{item.noches}}</div>
                                </td>
                                <td>
                                    <div *ngIf="item.adultos">Adultos: {{item.adultos}}</div>
                                    <div *ngIf="item.ninos">Ni&ntilde;os: {{item.ninos}}</div>
                                </td>
                                <td>
                                    <div>{{item.cli_nombre_completo}}</div>
                                    <div><a href="mailto:{{item.cli_email}}"><i class="fa fa-envelope"></i> {{item.cli_email}}</a></div>
                                    <div><a href="tel:{{item.cli_telefono}}"><i class="fa fa-phone"></i> {{item.cli_telefono}}</a></div>
                                </td>
                                <td class="font-bold text-primary text-center breaktd" (click)="Editar(item)">
                                  <div class="font-20">{{globals.FloatES(item.total)}} &euro;</div>
                                </td>
                                <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                              </tr>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="misreservas">
                            <tr *ngFor="let item of reservas" class="cursor {{item.id_estado == 4 || item.id_estado == 5 ? 'bg-tr-cancelada' : null}}" (click)="VistaPrevia(item)">
                              <td class="">
                                <div class="font-bold text-primary">Reserva #{{item.referencia}}</div>
                                <div class="font-bold">{{item.alojamiento}}</div>
                              </td>
                              <td>{{item.alta_format}}</td>
                              <td>
                                <div>{{item.estado}}</div>
                                <div *ngIf="item.forma_pago && item.forma_pago != '0'" class="font-11 text-primary">{{item.forma_pago}}</div>
                              </td>
                              <td>
                                  <div>Llegada: {{item.fecha_llegada_format}}</div>
                                  <div>Salida: {{item.fecha_salida_format}}</div>
                                  <div>Noches: {{item.noches}}</div>
                              </td>
                              <td>
                                  <div *ngIf="item.adultos">Adultos: {{item.adultos}}</div>
                                  <div *ngIf="item.ninos">Ni&ntilde;os: {{item.ninos}}</div>
                              </td>
                              <td class="breaktd">
                                  <div>{{item.cli_nombre_completo}}</div>
                                  <div><a href="mailto:{{item.cli_email}}"><i class="fa fa-envelope"></i> {{item.cli_email}}</a></div>
                                  <div><a href="tel:{{item.cli_telefono}}"><i class="fa fa-phone"></i> {{item.cli_telefono}}</a></div>
                              </td>
                              <td class="font-bold text-primary text-center breaktd">
                                <div class="font-20">{{globals.FloatES(item.total)}} &euro;</div>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                    </table>
                    <div class="dataTables_paginate paging_simple_numbers m-r-10">
                      <ul class="pagination justify-content-end">
                        <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                          <a (click)="Paging('prev')" class="page-link">Anterior</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                          <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                        </li>
                        <li class="paginate_button page-item active">
                          <a class="page-link cursor">{{params.page_index}}</a>
                        </li>
                        <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                          <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                        </li>
                        <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                          <a (click)="Paging('next')" class="page-link">Siguiente</a>
                        </li>
                      </ul>
                    </div>
                </div>
                
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>