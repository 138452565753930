<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/alojamientos']">Alojamientos</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <!-- <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -->
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">Datos generales</a></li>
                            <!-- <li><a href="#tab-imagenes"><i class="icofont-ui-image"></i> Imágenes</a></li> -->
                            <!-- <li><a href="#tab-caracteristicas"><i class="icofont-listing-box"></i> Características</a></li> -->
                            <!-- <li><a href="#tab-servicios"><i class="icofont-listing-box"></i> Servicios</a></li> -->
                            <li><a href="#tab-tarifas"><i class="icofont-credit-card"></i> Tarifas</a></li>
                            <li><a href="#tab-calendario"><i class="icofont-ui-calendar"></i> Calendario</a></li>
                            <li class="{{data.id && data.id != '' ? null : 'hidden'}}"><a href="#tab-minimo-noches" (click)="LoadFechasMinimoNoches()"><i class="icofont-bed"></i> Mínimo noches</a></li>
                            <!-- <li><a href="#block-tabs-settings" data-toggle="tooltip" title="" data-original-title="Settings"><i class="gi gi-settings"></i></a></li> -->
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">
                              
                            <div class="row">
                              <div class="col-lg-4 col-md-12">
                                <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                                  <i class="icofont-camera-alt font-50"></i>
                                </div>
                                <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                                  <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                                  <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                                  <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                                </div>
                                <hr>
                                <div class="row">
                                  <div class="col-sm-8 col-xs-12">
                                    <h4>Galería de imágenes</h4>
                                  </div>
                                  <div class="col-sm-4 col-xs-12 col-botones" *ngIf="!data.imagenes || data.imagenes.length <= 20">
                                    <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen</a>
                                  </div>
                                </div>
                                <div class="text-center" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                                  <h4>No hay imágenes aún</h4>
                                </div>
                                <div class="row gallery" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes" style="padding: 0 10px;">
                                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id" style="padding-left:5px;padding-right:5px;">
                                    <div class="gallery-image-container animation-fadeInQuick2Inv" style="margin-bottom:10px;">
                                        <img src="{{item.imagen}}" alt="Image">
                                        <a href="{{item.imagen}}" class="gallery-image-options" data-toggle="lightbox-image" title="Image Info">
                                          <h2 class="text-light" style="margin:0;font-size:12px"><strong>Ampliar</strong></h2>
                                          <i class="fa fa-search-plus fa-3x text-light" style="font-size:12px"></i>
                                        </a>
                                        <a class="btn btn-danger cursor absolute" style="top:0;right:0" (click)="DelImagen($event, item)"><i class="fa fa-trash"></i></a>
                                      </div>
                                  </div>
                                </div>
                                <hr>
                                <div class="row">
                                  <div class="col-sm-8 col-xs-12">
                                    <h4>Características</h4>
                                  </div>
                                  <div class="col-sm-4 col-xs-12 col-botones" *ngIf="!data.caracteristicas || data.caracteristicas.length <= 20">
                                    <a class="btn btn-primary m-l-20" (click)="AddCaracteristica()">Añadir característica</a>
                                  </div>
                                </div>
                                <div class="table-responsive table-grid table-caracteristicas">
                                  <table class="table table-striped table-bordered table-vcenter table-hover">
                                      <thead>
                                          <tr>
                                            <th style="width:50px"></th>
                                            <th style="width:50px">Icono</th>
                                            <th>Característica</th>
                                            <th style="width:50px">Borrar</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let item of data.caracteristicas" class="cursor" [attr.itemid]="item.id">
                                            <td><i class="fa fa-sort"></i></td>
                                            <td><i class="{{item.icono}}"></i></td>
                                            <td class="font-bold text-primary" (click)="EditCaracteristica(item)">
                                                <div [innerHTML]="item.caracteristica"></div>
                                            </td>
                                            <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelCaracteristica(item)"><i class="fa fa-trash text-white"></i></a></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              </div>
                              <div class="col-lg-8 col-md-12">
                                
                                <div class="row">
                                  <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary">Nombre *</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary">Descripci&oacute;n *</label>
                                      <textarea id="txtdescripcion" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.descripcion" maxlength="5000" rows="6"></textarea>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Adultos *</label>
                                      <input type="number" class="form-control" [(ngModel)]="data.adultos" maxlength="10">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Ni&ntilde;os</label>
                                      <input type="number" class="form-control" [(ngModel)]="data.ninos" maxlength="10">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Número mínimo noches para reservar *</label>
                                      <input type="number" class="form-control" [(ngModel)]="data.num_minimo_noches" maxlength="10">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Horario de llegada</label>
                                      <textarea id="txthorariollegada" type="text" class="form-control" placeholder="Ejemplo: De 09:00h a 11:00h" [(ngModel)]="data.horario_llegada" maxlength="300" rows="3"></textarea>
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Horario de salida</label>
                                      <textarea id="txthorariosalida" type="text" class="form-control" placeholder="Ejemplo: De 19:00h a 21:00h" [(ngModel)]="data.horario_salida" maxlength="300" rows="3"></textarea>
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Tama&ntilde;o (m<sup>2</sup>)</label>
                                      <input type="number" class="form-control" [(ngModel)]="data.tamano" maxlength="10">
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>

                        </div>
                        <div class="tab-pane" id="tab-imagenes">
                          <!-- <div class="text-right" *ngIf="!data.imagenes || data.imagenes.length <= 20">
                              <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen</a>
                          </div>
                          <hr>
                          <div class="text-center" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                            <h4>No hay imágenes aún</h4>
                          </div>
                          <div class="row gallery" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes">
                            <div class="col-xl-3 col-md-4 col-sm-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id">
                                <div class="gallery-image-container animation-fadeInQuick2Inv">
                                  <img src="{{item.imagen}}" alt="Image">
                                  <a class="btn btn-danger cursor absolute" style="top:0;right:0" (click)="DelImagen(item)"><i class="fa fa-trash"></i> Borrar</a>
                                </div>
                            </div>
                          </div>
                          -->
                        </div> 
                        <div class="tab-pane" id="tab-caracteristicas">
                            
                          <!-- <div class="text-right" *ngIf="!data.caracteristicas || data.caracteristicas.length <= 20">
                              <a class="btn btn-primary m-l-20" (click)="AddCaracteristica()">Añadir característica</a>
                          </div>
                          <div class="table-responsive table-grid">
                              <table class="table table-striped table-bordered table-vcenter table-hover">
                                  <thead>
                                      <tr>
                                        <th style="width:50px"></th>
                                        <th style="width:50px">Icono</th>
                                        <th>Característica</th>
                                        <th style="width:50px">Borrar</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let item of data.caracteristicas" class="cursor" [attr.itemid]="item.id">
                                        <td><i class="fa fa-sort"></i></td>
                                        <td><i class="{{item.icono}}"></i></td>
                                        <td class="font-bold text-primary" (click)="EditCaracteristica(item)">
                                            <div [innerHTML]="item.caracteristica"></div>
                                        </td>
                                        <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelCaracteristica(item)"><i class="fa fa-trash text-white"></i></a></td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div> -->

                        </div>
                        <div class="tab-pane" id="tab-servicios">
                            
                            <div class="text-right" *ngIf="!data.servicios || data.servicios.length <= 20">
                                <a class="btn btn-primary m-l-20" (click)="AddServicio()">Añadir servicio</a>
                            </div>
                            <div class="table-responsive table-grid">
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                    <thead>
                                        <tr>
                                          <th style="width:50px"></th>
                                          <th style="width:50px">Icono</th>
                                          <th>Servicio</th>
                                          <th style="width:50px">Borrar</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data.servicios" class="cursor" [attr.itemid]="item.id">
                                          <td><i class="fa fa-sort"></i></td>
                                          <td><i class="{{item.icono}}"></i></td>
                                          <td class="font-bold text-primary" (click)="EditarServicio(item)">
                                              <div>{{item.servicio}}</div>
                                          </td>
                                          <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelServicio(item)"><i class="fa fa-trash text-white"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="tab-pane" id="tab-tarifas">
                            
                          <div class="text-right m-b-10">
                            <div class="btn-group">
                              <a class="btn btn-sm btn-danger" (click)="DelAllTarifas()">Borrar todas las tarifas</a>
                              <a class="btn btn-sm btn-default" (click)="OrdenarTarifas()" *ngIf="data.id && data.id != ''">Ordenar automáticamente</a>
                              <a class="btn btn-sm btn-default" (click)="GenerarTarifas()">Generar tarifas por temporadas</a>
                              <a class="btn btn-sm btn-default" (click)="ImportarTarifas()">Importar tarifas</a>
                              <a class="btn btn-sm btn-primary" (click)="AddTarifa()">Añadir tarifa</a>
                            </div>
                          </div>
                          <div class="pull-left m-r-15 text-primary">
                            <div class="text-center">
                              <i class="fa fa-arrow-up"></i><br>
                              <span class="font-12">Prioridad<br>alta</span>
                            </div>
                            <div class="text-center m-t-20">
                              <i class="fa fa-arrow-down"></i><br>
                              <span class="font-12">Prioridad<br>baja</span>
                            </div>
                          </div>
                          <div class="table-responsive table-grid">
                              <table class="table table-striped table-bordered table-vcenter table-hover">
                                  <thead>
                                      <tr>
                                        <th style="width:50px"></th>
                                        <th style="width:100px">Tarifa</th>
                                        <th>Temporada</th>
                                        <th>Adultos</th>
                                        <th>Niños</th>
                                        <th>Precio</th>
                                        <th style="width:50px">Editar</th>
                                        <th style="width:50px">Borrar</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let item of data.tarifas; let i = index" class="cursor" [attr.itemid]="item.id">
                                        <td><i class="fa fa-sort"></i></td>
                                        <td class="font-bold" (click)="EditTarifa(item)">Tarifa #{{i+1}}</td>
                                        <td class="font-bold" (click)="EditTarifa(item)">
                                          <div *ngIf="item.temporada">{{item.temporada}}
                                            <small *ngIf="item.pordefecto" class="text-primary m-l-10">Por defecto</small>
                                          </div>
                                        </td>
                                        <td>{{item.adultos}}</td>
                                        <td>{{item.ninos}}</td>
                                        <td>{{globals.FloatES(item.precio)}}€ / noche</td>
                                        <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="EditTarifa(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                        <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelTarifa(item)"><i class="fa fa-trash text-white"></i></a></td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                          <p class="text-primary">El orden de las tarifas es muy importante en el cálculo del total de las reservas</p>

                        </div>
                        <div class="tab-pane" id="tab-calendario">
                          <div class="row">
                            <div class="col-lg-4 col-md-12 col-xs-12">
                              <h2>Sincronización calendarios</h2>
                              <div class="form-group">
                                <label class="text-primary">URL Calendario Booking (.ics)</label>
                                <input type="url" class="form-control" [(ngModel)]="data.url_calendario_booking" maxlength="400">
                              </div>
                              <div class="form-group">
                                <label class="text-primary">URL Calendario Airbnb (.ics)</label>
                                <input type="url" class="form-control" [(ngModel)]="data.url_calendario_airbnb" maxlength="400">
                              </div>
                              <ng-container *ngIf="data && data.id && data.id != ''">
                                <hr>
                                <h2>URL calendario reservas exportado (.ics)</h2>
                                <a [href]="globals.api_url+'/export-ics/'+data.id" target="_blank" class="text-primary">{{globals.api_url+'/export-ics/'+data.id}}</a>
                              </ng-container>
                            </div>
                            <div class="col-lg-8 col-md-12 col-xs-12">
                              <div class="pull-right" style="width: 50%;">
                                <div id="content-table-block-rangue">
                                  <h2>Bloqueo por rango de fechas</h2>
                                  <p>Rellena el día de inicio y el día final del rango de los días que quieres bloquear</p>
                                  <div id="content-imputs-rangue-block">
                                    <div style="display: flex;">
                                      <input id="date-rangue-block-start" type="date" class="form-control" style="margin-left: 10px; margin-right: 10px;"/>
                                      <input id="date-rangue-block-end" type="date" class="form-control" style="margin-left: 10px; margin-right: 10px;"/>
                                      <a class="btn btn-default" (click)="BloquearRangoFechas()" style="margin-left: 10px; margin-right: 10px;">Bloquear fechas</a>
                                    </div>
                                    <table class="table table-striped table-bordered table-vcenter table-hover" style="margin-top: 10px;">
                                      <thead>
                                        <tr>
                                          <th>Fecha inicio</th>
                                          <th>Fecha final</th>
                                          <th style="width:50px">Borrar</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let item of fechas_bloqueadas_rango" class="cursor" [attr.itemid]="item.id">
                                          <td>{{item.fecha_inicio_format}}</td>
                                          <td>{{item.fecha_fin_format}}</td>
                                          <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelFechasRangue(item)"><i class="fa fa-trash text-white"></i></a></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <h2>Fechas bloqueadas</h2>
                              <div id="calendario-cierres" class="calendario-open"></div>
                              <div class="legenda">
                                <div><span class="bloqueada"></span> Bloqueada</div>
                                <div><span class="bloqueada reservada"></span> Reservada Web</div>
                                <div><span class="bloqueada booking"></span> Booking</div>
                                <div><span class="bloqueada airbnb"></span> AirBnb</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tab-minimo-noches">
                          <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12">
                              <input type="text" id="calendario-noches" class="calendario-open input-group" style="display:none">
                              <div id="calendario-noches-container"></div>
                              <div class="clearfix"></div>
                              <div class="p-l-15">
                                <span class="m-r-15">Número noches mínimas para reserva:</span>
                                <input type="number" class="form-control inline-block" min="1" [(ngModel)]="num_minimo_noches" style="width:90px;">
                                <a id="btn-add-minimo-noches" class="btn btn-primary m-l-15" (click)="AddMinimoNoches()">Añadir</a>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12">
                              <table class="table table-striped table-bordered table-vcenter table-hover" style="margin-top: 10px;">
                                <thead>
                                  <tr>
                                    <th>Fecha inicio</th>
                                    <th>Fecha fin</th>
                                    <th>Mínimo</th>
                                    <th style="width:50px">Borrar</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of data.minimo_noches" class="cursor {{item.reciente ? 'animate__animated animate__fadeInDown' : null}}">
                                    <td>{{item.fecha_inicio_format}}</td>
                                    <td>{{item.fecha_fin_format}}</td>
                                    <td>{{item.num_minimo_noches}} noches</td>
                                    <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelMinimoNoche(item)"><i class="fa fa-trash text-white"></i></a></td>
                                  </tr>
                                </tbody>
                              </table>
                              <p *ngIf="!data.minimo_noches || !data.minimo_noches.length">Aún no se han añadido rangos de fecha</p>
                            </div>
                          </div>
                        </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-crear-caracteristica" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content modal-lg">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-5 col-xs-12">
              <div class="form-group">
                <label>Predefinidas</label><br>
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-ruler-alt-1', '35 m<sup>2</sup>')">
                  <i class="icofont-ruler-alt-1"></i>
                  35 m<sup>2</sup>
                </div>
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-user-alt-1', '2 personas')">
                  <i class="icofont-user-alt-1"></i>
                  2 personas
                </div>
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-sunny-day-temp', 'Climatizado')">
                  <i class="icofont-sunny-day-temp"></i>
                  Climatizado
                </div>
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-sunny-day-temp', 'Calefacción')">
                  <i class="icofont-sunny-day-temp"></i>
                  Calefacción
                </div>
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-no-smoking', 'No fumador')">
                  <i class="icofont-no-smoking"></i>
                  No fumador
                </div>
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-wifi', 'Wi-Fi')">
                  <i class="icofont-wifi"></i>
                  Wi-Fi
                </div>
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-imac', 'Televisor')">
                  <i class="icofont-imac"></i>
                  Televisor
                </div>
              </div>
            </div>
            <div class="col-md-7 col-xs-12">
              <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Característica <span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="text" class="form-control" [(ngModel)]="dataitem.caracteristica" maxlength="50">
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
                  <div class="col-lg-8 chosen-iconos cmb-icono-caracteristica">
                    <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
                    </select>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-caracteristica" (click)="GuardarCaracteristica()">Guardar</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-crear-servicio" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Servicio <span class="text-danger">*</span></label>
              <div class="col-lg-8">
                  <input type="text" class="form-control" [(ngModel)]="dataitem.servicio" maxlength="50">
              </div>
          </div>
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
              <div class="col-lg-8 chosen-iconos cmb-icono-servicio">
                <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
                </select>
              </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-servicio" (click)="GuardarServicio()">Guardar</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-crear-tarifa" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Por defecto (tarifa base)</label>
              <div class="col-lg-8">
                  <label class="switch switch-success"><input id="chk-pordefecto" type="checkbox" [(ngModel)]="dataitem.pordefecto"><span></span></label>
              </div>
          </div>
          <div class="form-group row {{dataitem.pordefecto ? 'hidden' : ''}}">
              <label class="col-lg-4 col-form-label">Temporada <span class="text-danger">*</span></label>
              <div class="col-lg-8">
                <select id="cmb-temporada" class="form-control" [(ngModel)]="dataitem.id_temporada">
                  <option *ngFor="let item of temporadas" [value]="item.id">{{item.temporada}}</option>
                </select>
              </div>
          </div>
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Adultos <span class="text-danger">*</span></label>
              <div class="col-lg-8">
                  <input type="number" class="form-control" placeholder="Nº Adultos" [(ngModel)]="dataitem.adultos" maxlength="20">
              </div>
          </div>
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Niños</label>
              <div class="col-lg-8">
                  <input type="number" class="form-control" placeholder="Nº Niños" [(ngModel)]="dataitem.ninos" maxlength="20">
              </div>
          </div>
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Precio <span class="text-danger">*</span></label>
              <div class="col-lg-8">
                  <input type="number" class="form-control" placeholder="Precio por noche" [(ngModel)]="dataitem.precio" maxlength="20"><br>
              </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-tarifa" (click)="GuardarTarifa()">Guardar</button>
        </div>
        </div>
    </div>
</div>
<div class="modal inmodal fade" id="modal-importar-tarifas" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title"></h4>
        </div>
        <div class="modal-body">
          <div class="form-group row">
              <label class="col-lg-4 col-form-label">Importar tarifas desde</label>
              <div class="col-lg-8">
                <select class="form-control" [(ngModel)]="dataitem.id">
                  <option *ngFor="let item of alojamientos" [value]="item.id">{{item.nombre}}</option>
                </select>
              </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-tarifa" (click)="ImportTarifas()">Importar tarifas</button>
        </div>
        </div>
    </div>
</div>