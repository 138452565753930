<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Configuración</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Configuración</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <!-- <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -->
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active"><a href="#tab-general">General</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-general">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Configuración principal</h2></div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Porcentaje anticipo</label>
                                            <div class="col-lg-6">
                                                <input type="number" class="form-control text-right" [(ngModel)]="data.porcentaje_anticipo" maxlength="20" pattern="/^\d+$/" min="1" max="100" step="1">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Días cancelación</label>
                                            <div class="col-lg-6">
                                                <input type="number" class="form-control text-right" [(ngModel)]="data.dias_cancelacion" maxlength="20" pattern="/^\d+$/" min="1" max="100" step="1">
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Salida / Llegada clientes en el mismo día</label>
                                            <div class="col-lg-6">
                                                <label class="switch switch-success"><input type="checkbox" class="chk-salida-llegada" [(ngModel)]="data.salida_entrada_mismo_dia"><span></span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>