import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-temporadas',
  templateUrl: './temporadas.component.html'
})
export class TemporadasComponent implements OnInit {
  public temporadas = [];
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.LoadTemporadas();
  }

  LoadTemporadas() {
    if (this.globals.GetData()) this.temporadas = this.globals.GetData('config-temporadas');
    this.globals.Api('/alo-temporadas').subscribe(data => {
      if (!data || !data.length) {
        this.temporadas = [];
        this.globals.SaveData([], 'config-temporadas');
        return;
      }
      this.temporadas = data;
      this.globals.SaveData(this.temporadas, 'config-temporadas');
    });
  }
  AddTemporada() {
    $('#modal-crear-temporada').modal('show');
    $('#modal-crear-temporada .modal-title').html('Nueva temporada');
    Object.keys(this.data).forEach(item => {this.data[item] = ''});
    $('.chk-dia-temporada').removeAttr('checked');
    setTimeout(() => {
      $('#modal-crear-temporada input[type="text"]')[0].focus();
    }, 500);
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
  }
  EditarTemporada(item:any) {
    $('#modal-crear-temporada').modal('show');
    $('#modal-crear-temporada .modal-title').html('Editar temporada');
    this.data = JSON.parse(JSON.stringify(item));
    if (this.data.dias_semana) {
      this.data.dias_semana.split(',').forEach(el => {
        $('.chk-dia-temporada[dia="'+el+'"]').prop('checked', true);
      });
    }
    setTimeout(() => {
      $('#modal-crear-temporada input[type="text"]')[0].focus();
    }, 500);
    $('.datepicker').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      autoclose: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy"
    });
    setTimeout(() => {
      this.appref.tick();
      if (this.data.fecha_inicio_format) $('#txtfechainicio').datepicker('setDate', this.data.fecha_inicio_format);
      if (this.data.fecha_fin_format) $('#txtfechafin').datepicker('setDate', this.data.fecha_fin_format);
    }, 200);
  }
  GuardarTemporada() {
    if ($('#txtfechainicio').val() != '') {
      this.data.fecha_inicio = moment($('#txtfechainicio').datepicker('getDate')).format('YYYY-MM-DD');
    } else {this.data.fecha_inicio = null;}
    if ($('#txtfechafin').val() != '') {
      this.data.fecha_fin = moment($('#txtfechafin').datepicker('getDate')).format('YYYY-MM-DD');
    } else {this.data.fecha_fin = null;}

    if (
      this.data.temporada == ''
      || !this.data.fecha_inicio
      || this.data.fecha_inicio == ''
      || !this.data.fecha_fin
      || this.data.fecha_fin == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    let dias = [];
    $('.chk-dia-temporada').each(function() {
      if ($(this).prop('checked')) {
        dias.push($(this).attr('dia'));
      }
    });
    if (dias && dias.length) this.data.dias_semana = dias.join(',');
    else this.data.dias_semana = null;

    if (!this.data.id || this.data.id == '') {
      $.buttonloading('btn-guardar-temporada', 'Enviando');
      this.globals.Api('/alo-temporadas-add', this.data).subscribe(data => {
        $.buttoncancelloading('btn-guardar-temporada');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-temporada').modal('hide');
        this.LoadTemporadas();
      }, error => {
        $.buttoncancelloading('btn-guardar-temporada');
      });
    } else {
      $.buttonloading('btn-guardar-temporada', 'Enviando');
      this.globals.Api('/alo-temporadas-edit', this.data).subscribe(data => {
        $.buttoncancelloading('btn-guardar-temporada');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        $('#modal-crear-temporada').modal('hide');
        this.LoadTemporadas();
      }, error => {
        $.buttoncancelloading('btn-guardar-temporada');
      });
    }
  }
  BorrarTemporada(item:any) {
    swal({
      title: "¿Borrar temporada?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/alo-temporadas-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          if (data.value == 'Tarifas asociadas') {
            swal('Tarifas asociadas', 'Existen tarifas asociadas a esta temporada. Por favor, borre primero las tarifas', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.temporadas.splice(this.temporadas.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }

}
